<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 提示信息 -->
            <alert-info :info="infodata"></alert-info>
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">监测机构信息</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.saveJson"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item label="监测机构名称" prop="jcjgmc">
                    <el-input
                        v-model="formdata.saveJson.jcjgmc"
                        onkeydown="if(event.keyCode===32) return false"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="组织机构代码" prop="jcjgdm">
                    <el-input
                        v-model="formdata.saveJson.jcjgdm"
                        onkeydown="if(event.keyCode===32) return false"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="负责人" prop="fzr">
                    <el-input
                        v-model="formdata.saveJson.fzr"
                        onkeydown="if(event.keyCode===32) return false"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="负责人联系电话" prop="fzrlxdh">
                    <el-input
                        v-model="formdata.saveJson.fzrlxdh"
                        onkeydown="if(event.keyCode===32) return false"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="联系人姓名">
                    <el-input
                        v-model="formdata.saveJson.lxrxm"
                        onkeydown="if(event.keyCode===32) return false"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="联系电话" prop="lxdh">
                    <el-input
                        v-model="formdata.saveJson.lxdh"
                        onkeydown="if(event.keyCode===32) return false"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="电子邮箱" prop="dzyx">
                    <el-input
                        v-model="formdata.saveJson.dzyx"
                        onkeydown="if(event.keyCode===32) return false"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="监测工作网站" prop="jcgzwz">
                    <el-input
                        v-model="formdata.saveJson.jcgzwz"
                        onkeydown="if(event.keyCode===32) return false"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <!-- 元上都没有 -->
                <!-- <el-form-item label="监测机构是否独立建制">
                    <el-radio-group v-model="formdata.saveJson.jcjgsfdljz" :disabled="!isEdit">
                        <el-radio label="是">是</el-radio>
                        <el-radio label="否">否</el-radio>
                    </el-radio-group>
                </el-form-item> -->
                <el-form-item label="机构级别">
                    <el-select
                        v-model="formdata.saveJson.jgjb"
                        filterable
                        clearable
                        :disabled="!isEdit"
                    >
                        <el-option
                            v-for="(da, index) in jgLevel"
                            :key="index"
                            :label="da"
                            :value="da"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="上级管理单位">
                    <el-tooltip
                        class="item"
                        effect="light"
                        content="填写所属管理责任机构"
                        placement="bottom"
                    >
                        <el-input
                            v-model="formdata.saveJson.sjgldw"
                            onkeydown="if(event.keyCode===32) return false"
                            :disabled="!isEdit"
                        >
                        </el-input>
                    </el-tooltip>
                </el-form-item>
                <el-form-item label="机构成立时间">
                    <el-date-picker
                        v-model="formdata.saveJson.jgclrq"
                        type="month"
                        value-format="yyyy-MM"
                        placeholder="输入日期"
                        onkeydown="if(event.keyCode===32) return false"
                        :disabled="!isEdit"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="机构撤销时间">
                    <el-date-picker
                        v-model="formdata.saveJson.jgcxrq"
                        type="month"
                        value-format="yyyy-MM"
                        placeholder="输入日期"
                        onkeydown="if(event.keyCode===32) return false"
                        :disabled="!isEdit"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="人员总数" prop="ryzs">
                    <el-tooltip
                        class="item"
                        effect="light"
                        content="填写在职的正式职工人数"
                        placement="bottom"
                    >
                        <el-input
                            v-model="formdata.saveJson.ryzs"
                            onkeydown="if(event.keyCode===32) return false"
                            :disabled="!isEdit"
                        >
                            <template slot="append">人</template>
                        </el-input>
                    </el-tooltip>
                </el-form-item>
                <el-form-item label="承担的责任" class="from-item-block">
                    <el-tooltip
                        class="item"
                        effect="light"
                        content="结合本遗产地的行政区域、遗产要素，填写监测机构所承担的责任，填写相应描述性信息，字数限制在2000字以内。"
                        placement="bottom"
                    >
                        <el-input
                            v-model="formdata.saveJson.cddzr"
                            :maxlength="2000"
                            show-word-limit
                            :autosize="{ minRows: 6 }"
                            type="textarea"
                            onkeydown="if(event.keyCode===32) return false"
                            :disabled="!isEdit"
                        >
                        </el-input>
                    </el-tooltip>
                </el-form-item>
                <el-form-item label="备注" class="from-item-block">
                    <el-input
                        v-model="formdata.saveJson.bz"
                        :maxlength="500"
                        show-word-limit
                        :autosize="{ minRows: 6 }"
                        type="textarea"
                        onkeydown="if(event.keyCode===32) return false"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import snButton from "../0_com_js/edit-button.js";
import infoMixin from "../0_com_js/info_mixin.js";
export default {
    name: "assembly_resolutions",
    components: {},
    mixins: [infoMixin],
    data() {
        let positiveNumber = (rule, value, callback) => {
            let reg = /^(0|\+?[1-9][0-9]*)$/;
            if (value && !reg.test(value)) {
                callback(new Error("请输入0或正整数"));
            } else {
                callback();
            }
        };
        let phoneValid = (rule, value, callback) => {
            let isPhone = /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/; // 固话号码带‘-’
            if (value && !isPhone.test(value)) {
                callback(new Error("请输入正确的手机号码或者座机号"));
            } else {
                callback();
            }
        };
        return {
            notNeedFile: true,
            snButton: snButton,
            rules: {
                fzr: [
                    {
                        required: true,
                        message: "请填写负责人",
                        trigger: "blur",
                    },
                ],
                jcjgmc: [
                    {
                        required: true,
                        message: "请填写监测机构名称",
                        trigger: "blur",
                    },
                ],
                fzrlxdh: [{ validator: phoneValid, trigger: "blur" }],
                lxdh: [{ validator: phoneValid, trigger: "blur" }],
                dzyx: [
                    {
                        type: "email",
                        message: "请输入正确的电子邮箱",
                        trigger: "blur",
                    },
                ],
                jcgzwz: [
                    {
                        type: "url",
                        message: "请输入正确的网址",
                        trigger: "blur",
                    },
                ],
                ryzs: [{ validator: positiveNumber, trigger: "blur" }],
            },
            // 机构级别
            jgLevel: [
                "正厅（局）级",
                "副厅（局）级",
                "正处（县）级",
                "副处（县）级",
                "正科（乡）级",
                "副科（乡）级",
                "股级",
                "副股级",
                "其他",
            ],
            formdata: {
                saveJson: {
                    jcjgmc: "",
                    jcjgdm: "",
                    fzr: "",
                    fzrlxdh: "",
                    lxrxm: "",
                    lxdh: "",
                    dzyx: "",
                    jcgzwz: "",
                    // jcjgsfdljz: '',
                    jgjb: "",
                    sjgldw: "",
                    jgclrq: "",
                    jgcxrq: "",
                    ryzs: "",
                    cddzr: "",
                    bz: "",
                    shzt: 1,
                },
                metaJson: [],
                heritageId: "",
                fileID: "",
                originalFileID: "",
                itemID: "18",
                userName: "",
                userId: "",
            },
        };
    },
    computed: {},
    mounted() {},
    methods: {},
};
</script>
<style lang="scss" scoped></style>
